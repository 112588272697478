<template>
    <div>
        <!-- FEATURES START -->
        <section class="section bg-light" id="service">
            <div class="container mt-10 mt-10">
                <div style="background: url('images/map.png') center center;">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="position-relative me-lg-5">
                                <img src="../../assets/images/kc_happy.jpeg" class="rounded img-fluid mx-auto d-block" alt="">
                                <!-- <div class="play-icon">
                                    <a href="#!" @click="toggle" data-type="youtube" data-id="yba7hPeTSjk"
                                        class="play-btn lightbox">
                                        <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                    </a>
                                </div> -->
                            </div>
                        </div><!--end col-->

                        <div v-for="item3 in BusinessData" :key="item3" class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="section-title">
                                <h4 class="title mb-3">{{ item3.title }}</h4>
                                <p class="text-muted">{{ item3.desc }}</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i :class=item3.icon></i></span>{{
                                        item3.icondesc }}</li>
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i :class=item3.icon></i></span>Absolutely free to use. We respect your privacy.</li>
                                    <li class="mb-1"><span class="text-primary h5 me-2"><i :class=item3.icon></i></span>{{
                                        item3.icondesc2 }}</li>
                                </ul>

                                <div class="d-inline-block">
                                    <div class="pt-3 d-flex align-items-center border-top">
                                        <i class="uil uil-envelope text-primary me-2 fs-1"></i>
                                        <div class="content">
                                            <p class="mb-0">Need More Help?</p>
                                            <a href="" class="h6">
                                                <router-link :to="{ name: 'GetStarted' }" class="text-primary">Ask us your question
                                                </router-link></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!--end col-->
                    </div><!--end row-->
                </div>
            </div><!--end container-->
            <!-- <div class="position-absolute  bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-teal-400 to-teal-500">
            </div> -->
        </section><!--end section-->
        <section class="d-flex justify-content-start" id="team-intro" @click="$router.push({ name: 'OurAgents' });">
                <div class="align-self-center" style="padding-left: 10%;">
                    <h2 class="text-light">Meet Our Team</h2>
                    <p class="para-desc">Our team of dedicated realtors is here to help you with all your real estate needs. Whether you're buying, selling, or investing, we have the expertise to guide you through every step of the process. Click here to meet them!</p>
                </div>
        </section>
        <!-- End -->
        <div :class="isActive ? 'position-fixed start-0 top-0 w-100 h-100' : 'd-none'" class="bg-black "
            style="z-index: 9999;">
            <div class="position-absolute top-50 start-50 translate-middle">
                <div class="position-relative d-inline-block">
                    <iframe src="" width="560" height="315" frameborder="0"
                        class="video-frame"></iframe>
                    <router-link to="/" class="mdi mdi-close   m-3 text-light h4 position-class"
                        @click="toggle"></router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
//import CountUp from 'vue-countup-v3'

export default {
    name: "FeatureVue",
    data() {
        return {
            isActive: false,
            FeatureData: [
                {
                    icon: "mdi mdi-language-php rounded h4",
                    title: "Financial Planning",
                    desc: "Horem ipsum dolor consectetuer Lorem simply dummy orem commo.",
                },
                {
                    icon: "mdi mdi-file-image rounded h4",
                    title: "Quality Resourcing",
                    desc: "When an unknown printer took a galley of type and scrambled it.",
                },
                {
                    icon: "uil uil-camera rounded h4",
                    title: "Business Services",
                    desc: "It has survived not only five centuries but leap in typesetting.",
                },

            ],
            FeatureData2: [
                {
                    icon: "mdi mdi-google-glass rounded h4",
                    title: "Software And Research",
                    desc: "It was popularised with the release of Letraset sheets sit amet.",
                },
                {
                    icon: "mdi mdi-source-commit rounded h4",
                    title: "Travel And Aviation",
                    desc: "It is a long established fact that a reader will be distracted.",
                },
                {
                    icon: "mdi mdi-code-tags rounded h4",
                    title: "Healthcare Services",
                    desc: "A point of using lorem ipsum is that it has normal distribution.",
                },
            ],
            BusinessData: [
                {
                    title: "Welcome to TexSmart Realty",
                    title2: " Hassle Free Business",
                    desc: "As a full service real estate professional in Texas, We work with buyers, sellers and investors in real estate transactions spanning all of the price ranges and property types.Our website contains ALL listings from ALL Real Estate Agents, there's no need to look anywhere else for your next home.",
                    icon: "uil uil-check-circle align-middle",
                    icondesc: "We keep updating you on latest Listings and Price Changes.",
                    icondesc2: "Automatically get signs delivered to your house + professional photographs taken."
                }
            ],
            Datas: [
                {
                    name: "Projects",
                    target: "40",
                    symbol: "+"
                },
                {
                    name: "Clients",
                    target: "200",
                    symbol: "+"
                },
                {
                    name: "Members",
                    target: "457",
                    symbol: "K"
                },
                {
                    name: "Employee",
                    target: "150",
                    symbol: "+"
                },
            ]
        }
    },
    /* components: {
        CountUp
    }, */
    methods: {
        toggle() {
            if (!this.isActive) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.position-class {
    position: absolute;
    bottom: 82%;
    left: 92%;
}
.features-list {
  display: grid !important;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

@media (max-width: 768px) {
  .features-list {
    grid-template-columns: auto;
  }
}

.feature-item {
    padding: 20px 30px 20px 30px;
    background: white;
    border-radius: 25px;
    //box-shadow: 0 0 10px 0 rgb(128,128,128,0.6);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.feature-item svg {
    color: #20A4F3;
}
.feature-item h4 {
    margin-top: 15px;
}
.title {
    color: #20A4F3;
}
#team-intro {
    cursor: pointer;
    height: 60vh;
    width:100%;
    background-image: url("../../assets/images/teampic.jpg");
    background-size: cover;
    opacity: 0.9;
}
</style>